import { Injectable } from "@angular/core";
import { AccountClient } from "../services-api/app-api";
import { ApplicationTranslateService } from "../services/application-translate.service";
import { AuthorizeService } from "../services/authorize.service";

@Injectable()
export class AppInitProvider {

  constructor(
    private readonly accountClient: AccountClient,
    private readonly authorizeService: AuthorizeService,
    private readonly applicationTranslate: ApplicationTranslateService
  ) { }

  /** Metoda służąca do pobrania danych przed wyświetleniem strony */
  getAppPreLoader() {
    return new Promise(async (resolve, reject) => {
      const result = await this.accountClient.getLogged().toPromise();
      if (result != null) {
        this.authorizeService.currentUser = result;
      }
      await this.applicationTranslate.getLanguages();
      await this.applicationTranslate.loadTranslations();

      resolve(true);
    });
  }
}

