import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LayoutService, MessageSeverity } from '../services/layout/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  maxResponseSizeMb = 20;

  constructor(
    private readonly router: Router,
    private messageService: MessageService,
    private readonly translateService: TranslateService) { }

  // https://medium.com/angular-in-depth/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event != null) {
          const contentLength = parseInt(event.headers.get("content-length")); // BYTES
          const contentLengthMb = contentLength / 1000000; // MegaBytes
          if (contentLengthMb > this.maxResponseSizeMb) {
            const responseExceeds = this.translateService.instant('error_server_response_exceeds');
            this.showCodeError(`${responseExceeds} ${this.maxResponseSizeMb}MB`);
          }
        }
        return event;
      },
        catchError((err: { status: string, statusText: string, error: { message: string } }) => {
          const errorLabel = this.translateService.instant('error');
          const errorMessage = this.translateService.instant(err.error.message);
          this.showCodeError(`${errorLabel} ${err.status} ${errorMessage}`);

          this.router.navigateByUrl('/');

          const error = (err != null && err.error != null) ? err.error.message : err.statusText;

          return throwError(error);
        })
      )
    )
  }

  showCodeError(error: string) {
    this.messageService.add({ severity: 'Error', summary: 'error_api', detail: error });
  }

}

export class ErrorModel {
  [key: string]: Array<string>;
}

export class RequestError {
  title: string;
  errors: ErrorModel;
}

export function defaultRequestErrorHandler(layoutService: LayoutService, error: RequestError, translateService: TranslateService) {
  const requestError = translateService.instant('error_request');
  console.error(`${requestError}: `, error);
  if (!error) {
    return;
  }

  if (error.title) {
    layoutService.showPopover(MessageSeverity.error, error.title);
  }

  const errors = error.errors; 
   
  if (errors) {
    for (const property in errors) {
      if (errors.hasOwnProperty(property) && Array.isArray(errors[property])) {
        (errors[property] as Array<string>).forEach((err: string) => {
          if (err) {
            layoutService.showPopover(MessageSeverity.error, translateService.instant(err));
          }
        });
      }
    }
  }
}

export class ErrorHelper {
  static setControlErrors(formModel: FormGroup, formFieldNames: Array<string>, errorModel: RequestError, errorFieldNames: Array<string> = null) {
    if (formFieldNames.length != errorFieldNames.length) {
      throwError("formFieldNames has different length than errorFieldNames!");
      console.error("formFieldNames has different length than errorFieldNames!", formModel, formFieldNames, errorFieldNames)
      return;
    }

    for (let i = 0; i < formFieldNames.length; i++) {
      let errorFieldName = errorFieldNames[i];
      let formFieldName = formFieldNames[i];

      errorFieldName = errorFieldName ? errorFieldName : formFieldName;

      let error = errorModel.errors[errorFieldName];
      let formControl = formModel.controls[formFieldName];

      if (error) {
        formControl.setErrors({ errorList: error });
      } else {
        formControl.setErrors(null);
      }

    };
  }

  static restetControlErrors(formModel: FormGroup, formFieldNames: Array<string>) {
    formFieldNames.forEach(field => {
      formModel.controls[field].setErrors(null);
    })
  }

  static getControlErrors(formModel: FormGroup, controlName: string): Array<string> {
    let control = formModel.get(controlName);

    if (control && control.errors)
      return control.errors.errorList;
    return new Array<string>();
  }
}
