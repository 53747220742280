import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { VersionComponent } from './version/version.component';



@NgModule({
  declarations: [HeaderComponent, MenuComponent, MenuItemComponent, VersionComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [HeaderComponent, MenuComponent, MenuItemComponent, VersionComponent]
})
export class LayoutModule { }
