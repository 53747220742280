import { Injectable } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { SignalR } from '../../signalr/signalr';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  signalR: SignalR;

  constructor(
    private readonly authorizeService: AuthorizeService,
  ) {
    this.signalR = new SignalR();
  }

  connect() {
    this.signalR.connect(() => {
      this.signalR.whenConected(() => {
        if (this.authorizeService.currentUser != null) {
          this.authorizeService.currentUser.roles.forEach(r => {
            this.signalR.joinGroup(r);
          });
        }
      });      
    });
  }

  disconnect() {
    this.signalR.disconnect();
  }

}
