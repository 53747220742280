<ng-container *ngIf="isAuthenticated; else anonymous">
  <p-sidebar [visible]="true" position="left" [closeOnEscape]="false" [dismissible]="false" [showCloseIcon]="false" [modal]="false" [style]="{width:'300px'}" class="p-d-flex p-pl-0">

    <app-menu></app-menu>

    <app-version></app-version>

  </p-sidebar>
  <div id="sidebar-ghost" class="p-d-flex">
  </div>

  <div id="app-body" class="p-flex-grow-1">
    <app-header class="p-d-flex p-flex-grow-1"></app-header>

    <router-outlet></router-outlet>
  </div>
</ng-container>

<ng-template #anonymous>
  <router-outlet></router-outlet>
</ng-template>

<p-toast position="bottom-right"></p-toast>
