export const environment = {
  production: true,
  dateTimeFormat: "DD.MM.YYYY HH:mm",
  dateFormat: "DD.MM.YYYY",
  primeng: {
    "pl": {
      startsWith: "Zaczyna się od",
      contains: "Zawiera",
      notContains: "Nie zawiera",
      endsWith: "Kończy się na",
      equals: "Równa się",
      notEquals: "Nie równa się",
      noFilter: "Brak filtrów",
      lt: "Mniejsze niż",
      lte: "Mniejsze lub równe",
      gt: "Większe niż",
      gte: "Większe lub równe",
      is: "Jest",
      isNot: "Nie jest",
      before: "Przed",
      after: "Po",
      apply: "Zastosuj",
      matchAll: "Dopasuj wszystkie",
      matchAny: "Dopasuj dowolny",
      addRule: "Dodaj zasadę",
      removeRule: "Usuń zasadę",
      accept: "Tak",
      reject: "Nie",
      choose: "Wybierz",
      upload: "Prześlij",
      cancel: "Anuluj",
      firstDayOfWeek: 1,
      dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
      dayNamesShort: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"],
      dayNamesMin: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"],
      monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
      monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
      today: 'Dzisiaj',
      clear: 'Wyczyść',
      weekHeader: 'Tyd',
    },

    "en": {
      primeng: {
        startsWith: "Starts with",
        contains: "Contains",
        notContains: "Not contains",
        endsWith: "Ends with",
        equals: "Equals",
        notEquals: "Not equals",
        noFilter: "No Filter",
        lt: "Less than",
        lte: "Less than or equal to",
        gt: "Greater than",
        gte: "Great then or equals",
        is: "Is",
        isNot: "Is not",
        before: "Before",
        after: "After",
        apply: "Apply",
        matchAll: "Match All",
        matchAny: "Match Any",
        addRule: "Add Rule",
        removeRule: "Remove Rule",
        accept: "Yes",
        reject: "No",
        choose: "Choose",
        upload: "Upload",
        cancel: "Cancel",
        firstDayOfWeek: 0,
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: "Today",
        clear: "Clear",
        weekHeader: "Wk",
      }
    }
  }
};
