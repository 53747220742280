import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizeService } from '../services/authorize.service';


/**
 * Sprawdzenie czy użytkownik jest zalogowany
 * Użycie autoryzacji
 * Routing:
 *      { path: 'xyz', loadChildren: 'xyz.module#xyzModule', canActivate: [IsAuthenticatedGuard], data: { redirectUrl: string} }
 *
 * */
@Injectable({ providedIn: 'root' })
export class IsAuthenticatedGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthorizeService
    ) { }

    /**
     * Ose only on component routing
     * data.redirectUrl: string
     * data: { redirectUrl: string}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUser;
        if (currentUser) {
            return true;
        }
        var redirectUrl = route.data.redirectUrl ? route.data.redirectUrl : "/user/login";

        this.router.navigate([redirectUrl], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
