import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from '../helpers/helper';
import { GetBaseDictionaryVm, SetDefaultLanguageCommand, TranslationClient } from '../services-api/app-api';
import { AuthorizeService } from './authorize.service';
import { LayoutService, MessageSeverity } from './layout/layout.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationTranslateService {
  languages: GetBaseDictionaryVm;
  defaultLanguage = 'pl';

  constructor(
    private readonly layoutService: LayoutService,
    private readonly translateService: TranslateService,
    private readonly translationClient: TranslationClient,
    private readonly authorizationService: AuthorizeService
  ) { }

  async getLanguages() {
    const result = await this.translationClient.getLanguages().toPromise()

    if (result && result.items) {
      this.languages = result;
    } else {
      this.languages = new GetBaseDictionaryVm();
    }
  }

  async loadTranslations(lang: string = null): Promise<object> {
    const appLang = 'pl';//lang != null ? lang : await this.getCurrentLang();

    Helper.SetSessionValue('ApplicationLanguage', appLang);

    const obj = { selectedApplicationLanguage: appLang };
    this.translateService.use(appLang);

    this.translateService.currentLang = appLang;
    const translationResult = await this.translationClient.getTranslationsForLanguage(appLang).toPromise();

    let trans: { [key: string]: string; } = {};
    translationResult.items.forEach(t => {
      trans[t.key] = t.value;
    });
    this.translateService.setTranslation(appLang, trans);

    return obj;
  }

  async changeUserDefaultLanugage(lang: string): Promise<void> {
    if (this.authorizationService.currentUser != null) {
      const setDefaultLanguageCommand = new SetDefaultLanguageCommand();
      setDefaultLanguageCommand.languageCode = lang;
      const result = await this.translationClient.setDefaultLanguage(setDefaultLanguageCommand).toPromise();
      if (result == null) {
        this.layoutService.showPopover(MessageSeverity.error, 'error_user_language_change');
      }
    }
  }

  async getCurrentLang(): Promise<string> {
    const userLang = this.authorizationService.currentUser
      && this.authorizationService.currentUser.languageCode != null
      && this.authorizationService.currentUser.languageCode !== '' ? this.authorizationService.currentUser.languageCode : null;
    if (userLang != null) {
      Helper.SetSessionValue('ApplicationLanguage', userLang);
      return userLang;
    } else {
      const sessionLang = Helper.GetSessionValue('ApplicationLanguage');
      return sessionLang != null && sessionLang !== '' ? sessionLang : this.defaultLanguage;
    }
  }
}
