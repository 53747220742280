import { Injectable, ElementRef } from '@angular/core';
import { MessageService, Message, ConfirmationService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { Helper } from '../../helpers/helper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private menuHidden: boolean = false;
  private viewName: string;

  constructor(
    private readonly messageService: MessageService,
    private readonly titleService: Title,
      private readonly confirmationService: ConfirmationService,
      private readonly translateService: TranslateService) {
  }


  public isMenuHidden() { return this.menuHidden; }
  public showMenu() { this.menuHidden = false; }
  public hideMenu() { this.menuHidden = true; }

  showPopoverBase(message: NgMessage) {
    this.messageService.add({ severity: message.severity, detail: message.detail, life: message.life });
  }
  showPopover(severity: MessageSeverity, detail: string, life: number = 5000) {
    const translatedDetail = this.translateService.instant(detail);
    this.messageService.add({ severity: severity, detail: translatedDetail, life: life });
  }

  setViewName(title: string) {
    this.viewName = title;
  }
  get getViewName() {
    return this.viewName;
  }
  setAppTitle(title: string, viewName: string = null) {
    viewName = viewName ? viewName : title;
    this.titleService.setTitle(`Veolia ${Helper.IsNullOrEmpty(title) ? '' : '-'} ${title}`);
    this.setViewName(viewName);
  }

  createDeleteMessage(message: string, acceptEvent: () => void, rejectEvent?: () => void) {
    const buttonAccept = this.translateService.instant('global_yes');
    const buttonReject = this.translateService.instant('global_no');
    const header = this.translateService.instant('confirmation');
    this.confirmationService.confirm({
      icon: '',
      acceptLabel: buttonAccept,
      rejectLabel: buttonReject,
      header: header,
      acceptIcon: '',
      acceptButtonStyleClass: 'app-btn app-btn-primary',
      rejectButtonStyleClass: 'app-btn app-btn-secondary',
      rejectIcon: '',
      message: message,
      accept: () => {
        if (acceptEvent) acceptEvent();
      },
      reject: () => {
        if (rejectEvent) rejectEvent();
      }
    });
  }

}

export class NgMessage implements Message {
  severity?: MessageSeverity;
  summary?: string;
  detail?: string;
  id?: any;
  key?: string;
  life?: number;
  sticky?: boolean;
  closable?: boolean;
  data?: any;
}

export enum MessageSeverity {
  success = 'success',
  info = 'info',
  warn = 'warn',
  error = 'error',
}
