import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainMenuItem } from '../../../../infrastructure/models/layout/main-menu-item.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {

  constructor(private readonly router: Router) {

  }

  @Input() item: MainMenuItem;

  onItemClick() {
    if (this.item.onClick)
      this.item.onClick();
    else
      this.router.navigate([this.item.url]);
  }
}
