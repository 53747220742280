import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Helper } from '../helpers/helper';
import { AuthorizeService } from '../services/authorize.service';
import { Claim } from '../enums/claims.enum';
import { AuthMethod } from '../enums/auth-method.enum';
import { Role } from '../enums/roles.enum';

/**
 * Sprawdzenie czy użytkownik posiada odpowiednie uprawnienia
 * Użycie autoryzacji
 * Routing:
 *      { path: 'xyz', loadChildren: 'xyz.module#xyzModule', canActivate: [IsAuthorizedGuard], data: {systemClaims: ClaimEnum[], claims: string[], authMethod: AuthMethod, redirectUrl: string} }
 *      
 * authMethod = AuthMethod.All | AuthMethod.Any
 * claims     = Lista wartości (claim.value) jako string
 * systemClaims = Lista wartości (ClaimEnum) jako enum [ClaimEnum]
 * redirectUrl = string
 * */
@Injectable({ providedIn: 'root' })
export class IsAuthorizedGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthorizeService
  ) { }

  /**
   * Ose only on component routing
   * data.systemClaims: ClaimEnum[]
   * data.claims: string[]
   * data.authMethod: AuthMethod
   * data.redirectUrl: string
   * data: {systemClaims: ClaimEnum[], claims: string[], authMethod: AuthMethod, redirectUrl: string}
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Aktualny uzytkownika 
    var redirectUrl = route.data.redirectUrl ? route.data.redirectUrl : "/user/login";

    if (this.authService.currentUser) {
      // Zmienne dla autoryzacji uprawnień systemowych
      var hasClaim = false;
      var routeClaimData = Helper.toArray<Claim>(route.data.systemClaims);

      // Zmienne dla autoryzacji uprawnień widoku
      var hasViewClaim = false;
      var routeViewClaimData = Helper.toArray<string>(route.data.claims);

      // Metoda autoryzacji (All - należy posiadać wszystki wymienione uprawnienia, Any - dowolne z podanych)
      var authMethod = route.data.authMethod != null ? route.data.authMethod as AuthMethod : AuthMethod.All;

      // Brak wymaganych uprawnień
      if ((routeClaimData == null || routeClaimData.length == 0) && (routeViewClaimData == null || routeViewClaimData.length == 0))
        return true;

      // Uprawnienia użytkownika
      var userClaims = this.authService.currentUser.permissions;

      // Super administrator
      if (this.authService.hasRole(Role.Admin))
        return true;

      // Autoryzacja dowolnego uprawnienia
      if (authMethod == AuthMethod.Any) {
        // Sprawdzenie uprawnień systemowych
        hasClaim = routeClaimData.any<Claim>(a => {
          return userClaims.any<string>(b => {
            return b == a
          })
        });

        // Sprawdzenie uprawnień widoku
        hasViewClaim = routeViewClaimData.any<string>(a => {
          return userClaims.any<string>(b => {
            return b == a
          })
        });

        // Autoryzacja wszystkich uprawnień uprawnienia
      } else {
        // Sprawdzenie uprawnień systemowych
        hasClaim = routeClaimData.all<Claim>(a => {
          return userClaims.any<string>(b => {
            return b == a
          })
        });

        // Sprawdzenie uprawnień widoku
        hasViewClaim = routeViewClaimData.all<string>(a => {
          return userClaims.any<string>(b => {
            return b == a
          })
        });
      }
      // Jeśli użytkownik nie posiada uprawnień widoku ani systemowych to powrót na stronę główną
      if (!hasClaim && !hasViewClaim) {
        this.router.navigate(["/"]);
        return false;
      }

      // Autoryzowany
      return true;
    }

    // Nie zalogowany -> redirect do strony logowania
    this.router.navigate([redirectUrl], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
