import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'; 
import { LayoutService, MessageSeverity } from '../infrastructure/services/layout/layout.service';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from '../environments/environment';
import { ApplicationTranslateService } from '../infrastructure/services/application-translate.service';
import { AccountClient, GetBaseDictionaryVm, TranslationClient } from '../infrastructure/services-api/app-api';
import { Router } from '@angular/router';
import { AuthorizeService } from '../infrastructure/services/authorize.service';
import { tap } from 'rxjs/operators'; 
import { SignalRService } from '../infrastructure/services/signalr/signal-r.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  
  isAuthenticated = false; 

  constructor(public readonly layoutService: LayoutService,
    private readonly applicationTranslate: ApplicationTranslateService,
      private readonly authorizeService: AuthorizeService,
    private readonly accountClient: AccountClient,
    private readonly signalRService: SignalRService,
      private config: PrimeNGConfig 
  ) {
    this.checkAuthentication();
    this.layoutService.setAppTitle("");

  }

  ngOnInit() {
    this.config.setTranslation(environment.primeng['pl']);
  }

  checkAuthentication() {
    this.authorizeService.isAuthenticated().subscribe(result => {
      if (result != null) {
        this.isAuthenticated = result;

        this.accountClient.getLogged().subscribe(result => {
          this.authorizeService.currentUser = result;
          this.signalRService.connect();
        });     
      }
    });
  }
}
