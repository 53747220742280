import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { TableModule } from 'primeng/table';
import { EditorModule } from 'primeng/editor';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { SidebarModule } from 'primeng/sidebar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputMaskModule } from 'primeng/inputmask';
import { SkeletonModule } from 'primeng/skeleton';
import { TabMenuModule } from 'primeng/tabmenu';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ChartModule } from 'primeng/chart';

import { SanitizeHtmlPipe } from '../../infrastructure/pipes/sanitize-html.pipe';
import { CardComponent } from './card/card.component';
import { NoteComponent } from './note/note.component';
import { TableFilterPanelComponent } from './table-filter-panel/table-filter-panel.component';
import { ElementAddedViewComponent } from './element-added-view/element-added-view.component';
import { ContentDisplayLoaderComponent } from './content-display-loader/content-display-loader.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { DayEventsListComponent } from './day-events-list/day-events-list.component';
@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    CardComponent,
    NoteComponent,
    TableFilterPanelComponent,
    ElementAddedViewComponent,
    ContentDisplayLoaderComponent,
    SubmitButtonComponent,
    DayEventsListComponent
  ],
  imports: [
    ProgressSpinnerModule,
    FullCalendarModule,
    FieldsetModule,
    EditorModule,
    InputMaskModule,
    TriStateCheckboxModule,
    SkeletonModule,
    ConfirmPopupModule,
    SidebarModule,
    BreadcrumbModule,
    TabMenuModule,
    ChartModule,
    PanelModule,
    InputTextModule ,
    CommonModule,
    InputNumberModule,
    InputSwitchModule,
    TableModule,
    ToastModule,
    BlockUIModule,
    DialogModule,
    CardModule,
    InputTextareaModule,
    ListboxModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    TabViewModule,
    ReactiveFormsModule,
    PasswordModule,
    TieredMenuModule,
    FormsModule,
    CheckboxModule,
    RadioButtonModule,
    ButtonModule,
    DropdownModule,
    RouterModule, 
    CalendarModule,
    MultiSelectModule,
    TranslateModule

  ],
  exports: [
    SanitizeHtmlPipe,
    CardComponent,
    NoteComponent,
    TableFilterPanelComponent,
    ElementAddedViewComponent,
    ContentDisplayLoaderComponent,
    SubmitButtonComponent,
    DayEventsListComponent,

    ProgressSpinnerModule,
    ChartModule,
    FullCalendarModule,
    InputSwitchModule,
    ListboxModule, 
    PasswordModule,
    InputTextareaModule,
    TriStateCheckboxModule,
    FieldsetModule,
    ConfirmPopupModule,
    EditorModule,
    SkeletonModule,
    InputMaskModule,
    TieredMenuModule,
    BreadcrumbModule,
    BlockUIModule,
    DialogModule,
    PanelModule,
    TabMenuModule,
    CommonModule,
    TabViewModule,
    OverlayPanelModule,
    InputNumberModule,
    InputTextModule,
    ConfirmDialogModule,
    TableModule,
    ToastModule,
    ReactiveFormsModule,
    ButtonModule,
    SidebarModule,
    FormsModule,
    CheckboxModule,
    RadioButtonModule,
    CardModule,
    DropdownModule, 
    RouterModule, 
    CalendarModule,
    MultiSelectModule,
    TranslateModule
  ]
})

export class SharedModule { }
