import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthMethod } from '../../../infrastructure/enums/auth-method.enum';
import { Claim } from '../../../infrastructure/enums/claims.enum';
import { Role } from '../../../infrastructure/enums/roles.enum';
import { MainMenuItem } from '../../../infrastructure/models/layout/main-menu-item.model';
import { AccountClient, GetBaseDictionaryVm, TranslationClient } from '../../../infrastructure/services-api/app-api';
import { ApplicationTranslateService } from '../../../infrastructure/services/application-translate.service';
import { AuthorizeService } from '../../../infrastructure/services/authorize.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  menuItems: Array<MainMenuItem>;
  languages: GetBaseDictionaryVm;

  constructor(
    private readonly accountClient: AccountClient,
    private readonly translate: TranslateService,
    private readonly authorizeService: AuthorizeService,
    private readonly applicationTranslate: ApplicationTranslateService,
    private readonly translationClient: TranslationClient,
    private readonly router: Router) {
    this.getLanguages();
    this.initMenu();
  }

  initMenu() {
    this.menuItems = new Array<MainMenuItem>();

    if (!this.authorizeService.hasRoles([Role.GateOperator], AuthMethod.Any))
      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Strona główna';
        opts.icon = 'fa fa-th-large';
        opts.url = '/veolia/dashboard'
      }));

    if (this.authorizeService.hasRoles([Role.Admin, Role.Planner, Role.Specialist, Role.Contractor], AuthMethod.Any))
      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Planowanie';
        opts.icon = 'fa fa-code-branch';
        opts.url = '/veolia/planning'
      }));

    if (this.authorizeService.hasRoles([Role.Admin, Role.Planner, Role.Specialist, Role.Warehouseman, Role.Contractor], AuthMethod.Any)) {
      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Awizacje';
        opts.icon = 'fa fa-check-circle';
        opts.url = '/veolia/monitory-note'
      }));

      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Kierowcy';
        opts.icon = 'fa fa-id-card';
        opts.url = '/dictionary/driver'
      }));

      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Pojazdy';
        opts.icon = 'fa fa-truck';
        opts.url = '/dictionary/vehicle'
      }));
    }

    if (this.authorizeService.hasRoles([Role.Admin, Role.Planner, Role.Specialist, Role.Warehouseman], AuthMethod.Any)) {
      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Kontrahenci';
        opts.icon = 'fa fa-users';
        opts.url = '/dictionary/contractor'
      }));

      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Asortyment';
        opts.icon = 'fa fa-archive';
        opts.url = '/dictionary/assortment'
      }));
    }

    if (this.authorizeService.hasRoles([Role.Admin, Role.Planner, Role.Specialist], AuthMethod.Any))
      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Raporty';
        opts.icon = 'fa fa-chart-bar';
        opts.url = '/veolia/raports'
      }));

    if (this.authorizeService.hasRoles([Role.Admin, Role.GateOperator], AuthMethod.Any))
      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Widok wartownika';
        opts.icon = 'fa fa-id-card';
        opts.url = '/veolia/sentry-view'
      }));

    if (this.authorizeService.hasRoles([Role.Admin, Role.Planner], AuthMethod.Any))
      this.menuItems.push(new MainMenuItem(opts => {
        opts.name = 'Panel administracyjny';
        opts.icon = 'fa fa-sliders';
        opts.url = '/administration'
      }));

    // LOGOUT & LANGUAGE

    this.menuItems.push(new MainMenuItem(opts => {
      opts.name = 'Wyloguj';
      opts.icon = 'fa fa-sign-out';
      opts.url = 'authentication/logout'
      opts.onClick = () => { this.logout() };
    }));
  }

  async changeLanguage(lang: string): Promise<void> {
    if (lang != null) {
      this.applicationTranslate.loadTranslations(lang);
      this.applicationTranslate.changeUserDefaultLanugage(lang);
    }
  }

  async getLanguages(): Promise<void> {
    if (this.applicationTranslate.languages != null) {
      this.languages = this.applicationTranslate.languages;
    } else {
      const result = await this.translationClient.getLanguages().toPromise();
      if (result != null) {
        this.languages = result;
      }
    }
  }

  logout() {
    this.accountClient.logout().subscribe(result => {
      this.router.navigate(['authentication/logout']);
    });
  }

  languagesArray() {
    return Object.keys(this.languages.items);
  }
}
