import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { AuthorizeInterceptor } from '../infrastructure/interceptors/authorize.interceptor';
import { ErrorInterceptor } from '../infrastructure/interceptors/error-interceptor';
import { AppInitProvider } from '../infrastructure/providers/app-init.provider';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { MessageService, ConfirmationService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '../extend.ts';

import PL_LOCALE from '@angular/common/locales/pl';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IsAuthenticatedGuard } from '../infrastructure/guards/is-authenticated-guard';
import { IsAuthorizedGuard } from '../infrastructure/guards/is-authorized-guard';
import { AuthMethod } from '../infrastructure/enums/auth-method.enum';
import { Claim } from '../infrastructure/enums/claims.enum';
import { ExternalLoginService } from '../infrastructure/services/external-login/external-login.service';

registerLocaleData(PL_LOCALE, 'pl');

export function appInitProviderFactory(provider: AppInitProvider) {
  return () => provider.getAppPreLoader();
}

const routes: Routes = [
  { path: '', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  {
    path: 'dictionary', loadChildren: () => import('./dictionary/dictionary.module').then(m => m.DictionaryModule),
    canActivate: [IsAuthenticatedGuard, IsAuthorizedGuard],
    data: { systemClaims: [Claim.ViewVehicle, Claim.ViewDriver, Claim.ViewAssortment, Claim.ViewContractor], authMethod: AuthMethod.Any }
  },
  {
    path: 'veolia', loadChildren: () => import('./veolia/veolia.module').then(m => m.VeoliaModule),
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'administration', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'administration/:tab', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [IsAuthenticatedGuard]
  },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    SharedModule,
    LayoutModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
    })
  ],
  providers: [
    DatePipe,
    ConfirmationService,
    MessageService,
    AppInitProvider,
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: APP_INITIALIZER, useFactory: appInitProviderFactory, deps: [AppInitProvider], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ExternalLoginService
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
