import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';

export class SignalR {

  connection: HubConnection;

  retryDelays = [
    5000, 5000, 5000, 5000, 5000,
    10000, 20000, 30000, 40000, 50000,
    60000, 120000, 300000];

  connect(onConnect: () => void = () => { }) {

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("/mainhub")
      .configureLogging(signalR.LogLevel.Warning)
      .withAutomaticReconnect(this.retryDelays)
      .build();

    this.connection.start()
      .then(result => {
        onConnect();
      })
      .catch(error => {
        this.restartSignalr(error, onConnect);
      });

    this.connection.onreconnected((connectionId) => {
      onConnect();
    });

    this.connection.onclose(error => {
      this.restartSignalr(error);
    });
  }

  disconnect() {
    this.connection.stop()
  }

  onSendMessage() {
    this.connection.invoke('SendMessage', `User ${this.connection.connectionId}`, "Test message")
  }

  public invoke(method: string, object: any) {
    this.connection.send(method, object)
      .catch((error) => { console.error(error); });
  }

  public onReceive<Type>(methodName: string, onReceiveHandler: (response: Type) => void) {

    this.connection.on(methodName, (response: Type) => {
      onReceiveHandler(response);
    });
  }

  public offReceive(methodName: string) {
    this.connection.off(methodName);
  }

  whenConected(handler: () => void) {
    let interval = setInterval(() => {
      if (this.connection && this.connection.state == HubConnectionState.Connected) {
        handler();
        clearInterval(interval);
      }
    }, 1000);
  }

  public joinGroup(groupName: string) {
    this.connection.send('JoinGroup', groupName)
      .catch((error) => { console.error(error, groupName); });
  }

  public leaveGroup(groupName: string) {
    this.connection.send('LeaveGroup', groupName)
      .catch((error) => { console.error(error, groupName); });
  }

  private restartSignalr(error: Error, onConnect: () => void = () => { }) {
    console.error(error.toString());
    setTimeout(() => {
      this.connect(onConnect);
    }, 2000);
  }
}
