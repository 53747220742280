export enum Claim {
  ImportFromExcel = "ImportFromExcel",
  ViewSoftDeletedEntities = "ViewSoftDeletedEntities",
  ViewApplicationLogs = "ViewApplicationLogs",
  ViewTranslations = "ViewTranslations",
  ViewTranslationKeys = "ViewTranslationKeys",
  ViewLanguages = "ViewLanguages",
  ViewDictionaries = "ViewDictionaries",
  EditDictionaries = "EditDictionaries",
  AddUsers = "AddUsers",
  EditUsers = "EditUsers",
  ViewUsers = "ViewUsers",
  DeleteUsers = "DeleteUsers",
  LockoutUsers = "LockoutUsers",
  ViewAssortment = "ViewAssortment",
  AddAssortment = "AddAssortment",
  EditAssortment = "EditAssortment",
  DeleteAssortment = "DeleteAssortment",
  ViewContractor = "ViewContractor",
  AddContractor = "AddContractor",
  EditContractor = "EditContractor",
  DeleteContractor = "DeleteContractor",
  ViewVehicle = "ViewVehicle",
  AddVehicle = "AddVehicle",
  EditVehicle = "EditVehicle",
  DeleteVehicle = "DeleteVehicle",
  ViewDriver = "ViewDriver",
  AddDriver = "AddDriver",
  EditDriver = "EditDriver",
  DeleteDriver = "DeleteDriver",
  ViewPlan = "ViewPlan",
  AddPlan = "AddPlan",
  EditPlan = "EditPlan",
  DeletePlan = "DeletePlan",
  ApprovePlan = "ApprovePlan",
  ApprovePlanByAdmin = "ApprovePlanByAdmin",
  ApprovePlanByPlanner = "ApprovePlanByPlanner",
  ApprovePlanBySpecialist = "ApprovePlanBySpecialist",
  ApprovePlanByContractor = "ApprovePlanByContractor",
  AddPlanNotes = "AddPlanNotes",
  ViewDelivery = "ViewDelivery",
  EditDelivery = "EditDelivery",
  DeleteDelivery = "DeleteDelivery",
  ApproveContractorDelivery = "ApproveDelivery",
  AddDeliveryNotes = "AddDeliveryNotes",
  ViewMonitoryNote = "ViewMonitoryNote",
  AddMonitoryNote = "AddMonitoryNote",
  EditMonitoryNote = "EditMonitoryNote",
  CopyMonitoryNote = "CopyMonitoryNote",
  AcceptMonitoryNote = "AcceptMonitoryNote",
  CancelMonitoryNote = "CancelMonitoryNote",
  SamplingMonitoryNote = "SamplingMonitoryNote",
  ViewSentryView = "ViewSentryView",
  AddCardSentryView = "AddCardSentryView",
  ConfrimSentryView = "ConfrimSentryView",
  ViewEmailSettings = "ViewEmailSettings",
  EditEmailSettings = "EditEmailSettings",
  ViewCalendarEvent = "ViewCalendarEvent",
  AddCalendarEvent = "AddCalendarEvent",
  EditCalendarEvent = "EditCalendarEvent",
  DeleteCalendarEvent = "DeleteCalendarEvent",
  GenerateAccessToken = "GenerateAccessToken",
}
