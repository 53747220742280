import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { AccountClient, ExternalLoginProvidersCommand, GetExternalLoginProvidersInfoVm } from '../../../infrastructure/services-api/app-api'

@Injectable({
  providedIn: 'root'
})
export class ExternalLoginService {

  private providersInfo: GetExternalLoginProvidersInfoVm;
  private authService: SocialAuthService;

  constructor(
    private readonly accountClient: AccountClient,
  ) {

    accountClient.externalLoginProviders(new ExternalLoginProvidersCommand()).subscribe((providers) => {

      this.providersInfo = providers;
      this.initAuthService();
    }, (err) => {

      console.error('Could not retrive info about enabled external login providers', err);
    });
  }
  
  private initAuthService() {

    const providers: any[] = [];

    if (this.providersInfo.googleProvider) {

      providers.push({
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(this.providersInfo.googleProvider.clientId)
      });
    }

    this.authService = new SocialAuthService({
      autoLogin: false,
      providers: providers,
      onError: (err) => {

        console.error('External login failed with error', err);
      }
    });
  }

  public getProvidersInfo(): GetExternalLoginProvidersInfoVm {
    return this.providersInfo;
  }

  public getAuthService(): SocialAuthService {
    return this.authService;
  }
}
