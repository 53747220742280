export class Helper {
  // STRING
  static IsNullOrEmpty(text: string): boolean {
    return text == null || text == "";
  }

  static Includes(searchString: string, text: string): boolean {

    return searchString != null && text != null && searchString.toLowerCase().includes(text.toLowerCase());
  }

  static ToString<Type>(json: Type): string {
    return JSON.stringify(json);
  }

  static ToJson<Type>(json: string): Type {
    return JSON.parse(json) as Type
  }

  // SESSION
  static GetSessionValue(sessionKey: string): string {
    sessionStorage.getItem(sessionKey);
    return localStorage.getItem(sessionKey);
  }

  static GetSessionValueOfType<Type>(sessionKey: string): Type {
    return Helper.ToJson<Type>(Helper.GetSessionValue(sessionKey));
  }

  static SetSessionValue(sessionKey: string, sessionValue: string): void {
    sessionStorage.setItem(sessionKey, sessionValue);
    localStorage.setItem(sessionKey, sessionValue);
  }

  static SetSessionValueOfType<Type>(sessionKey: string, sessionValue: Type): void {
    Helper.SetSessionValue(sessionKey, Helper.ToString<Type>(sessionValue));
  }

  static RemoveSession(sessionKey: string) {
    sessionStorage.removeItem(sessionKey);
    localStorage.removeItem(sessionKey);
  }

  static ClearSession() {
    sessionStorage.clear();
    localStorage.clear();
  }

  // ARRAY
  static Contains<Type>(array: Array<Type>, element: Type): boolean {
    if (!Helper.isNullOrEmpty(array))
      return array.includes(element);
    return false;
  }

  static Sum(array: Array<number>): number {
    let result = 0;

    array.forEach(n => { if (n) result += n })

    return result;
  }

  static Max(array: Array<number>) {
    return Math.max(...array);
  }

  static getFormatedDateUtcString(value: Date) {
    return `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`
  }
  static getFormatedUtcString(value: Date) {
    return `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()} ${value.getUTCHours()}:${value.getUTCMinutes()}:${value.getSeconds()}`
  }
  /**
   * Checks if sourceArray contains all elements from targetArray
   * @param sourceArray Array with values to compare
   * @param targetArray Array with required values 
   */
  static HasAll<Type>(sourceArray: Array<Type>, targetArray: Array<Type>): boolean {
    return targetArray.every(sa => sourceArray.some(ta => ta == sa));
  }
  /**
   * Checks if sourceArray contains any element from targetArray
   * @param sourceArray Array with values to compare
   * @param targetArray Array with required values 
   */
  static HasAny<Type>(sourceArray: Array<Type>, targetArray: Array<Type>): boolean {
    return targetArray.some(sa => sourceArray.some(ta => ta == sa));
  }

  static IndexOf<Type>(array: Array<Type>, element: Type): number {
    if (!Helper.isNullOrEmpty(array))
      return array.indexOf(element);
    return -1;
  }

  //static IndexOfByProperty<Type, ValueType>(array: Array<Type>, fieldName: string, value: ValueType): number {
  //  if (!Helper.isNullOrEmpty(array)) {
  //    let arrayObject = array.filter(a => a[fieldName] == value)

  //    if (arrayObject && arrayObject.length == 1)
  //      return Helper.IndexOf(array, arrayObject[0]);
  //  }
  //  return -1;
  //}

  static RemoveElement<Type>(array: Array<Type>, element: Type): Array<Type> {
    array.splice(Helper.IndexOf(array, element), 1);
    return array;
  }

  static RemoveElementByIndex<Type>(array: Array<Type>, index: number): Array<Type> {
    array.splice(index, 1);
    return array;
  }

  static isNullOrEmpty<Type>(array: Array<Type>): boolean {
    return array == null || array.length == 0
  }

  static toArray<Type>(list: Type[]): Array<Type> {
    if (list) {
      return <Array<Type>>list as Array<Type>;
    } else {
      return new Array<Type>();
    }
  }

  static StringJoin(array: string[], separator: string = "<br>", elementTransform: (item: string) => string = (item) => { return item; }): string {
    let text = "";
    let addSeparator = false;

    for (let value of array) {

      if (addSeparator)
        text += `${separator} `;

      text += elementTransform(value);
      addSeparator = true;
    }

    return text;
  }

  // DATE
  static IsValidDate(date: Date): boolean {
    return date != null && !isNaN(date.getTime()) && date instanceof Date;
  }

  static IsDateBetween(dateStart: Date, dateEnd: Date, date: Date): boolean {
    return date > dateStart && date < dateEnd;
  }

  static CompareDates(date1: Date, date2: Date) {
    return date1.getFullYear() == date2.getFullYear() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getDate() == date2.getDate();

  }
  static CompareTimes(date1: Date, date2: Date) {
    return date1.getHours() == date2.getHours() &&
      date1.getMinutes() == date2.getMinutes() &&
      date1.getSeconds() == date2.getSeconds();

  }
  static CompareDateTimes(date1: Date, date2: Date) {
    return Helper.CompareDates(date1, date2) && Helper.CompareTimes(date1, date2);
  }

  static AddDays(date: Date, days: number) {
    var date = new Date(date.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  static GetDatesBetween(startDate: Date, stopDate: Date): Array<Date> {
    var dateArray = new Array<Date>();

    if (startDate && stopDate) {
      var currentDate = startDate;
      dateArray.push(new Date(currentDate));
      currentDate = Helper.AddDays(currentDate, 1);

      currentDate.setHours(0, 0, 0, 0);
      stopDate.setHours(0, 0, 0, 0);

      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = Helper.AddDays(currentDate, 1);
      }
    }

    return dateArray;
  }
  static GetDatesBetweenStringDates(startDateString: string, stopDateString: string): Array<Date> {
    var dateArray = new Array<Date>();

    let startDate = new Date(startDateString);
    let stopDate = new Date(stopDateString);

    if (Helper.IsValidDate(startDate) && Helper.IsValidDate(stopDate)) {

      if (startDate && stopDate) {
        var currentDate = startDate;
        while (currentDate <= stopDate) {
          dateArray.push(new Date(currentDate));
          currentDate = Helper.AddDays(currentDate, 1);
        }
      }
    }

    return dateArray;
  }

  // NUMBER
  static GetRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  // OBJECT

  static Serialize<Type>(object: Type): string {
    return JSON.stringify(object);
  }

  static Deserialize<Type>(objectJson: string): Type {
    return JSON.parse(JSON.stringify(objectJson)) as Type;
  }

  static IsNullOrUndefined(object: object): boolean {
    return object == null || object == undefined;
  }

  //static EnumToList<Type>(enumType: object): Array<Type> {
  //  var result = new Array<Type>();

  //  for (var n in enumType) {
  //    if (typeof enumType[n] !== 'number') {
  //      result.push(enumType[n] as Type);
  //    }
  //  }

  //  return result;
  //}

  // GUID
  static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0;
      const v = (c == 'x') ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // URL
  static openUrlInNewTab(url: string): void {
    window.open(url, "_blank");
  }

  // HTML HELPERS
  static afterDomElementInit(id: string, elementAction: (element: HTMLElement) => void, intervalTimeout: number = 50) {
    let interval = setInterval(() => {
      let domElement = document.getElementById(id);
      if (domElement) {
        elementAction(domElement);
        clearInterval(interval);
      }
    }, intervalTimeout);
  }

  static b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    b64Data = b64Data.split(',')[1];

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
