import { release } from "os";

declare global {
    interface Array<T> {
        /**
         * Sprawdzenie czy wszystkie elementy spełniają warunek
         * @param predicate Warunek
         */
        all<T>(predicate: (item: T) => boolean): boolean;

        /**
         * Sprawdzenie czy jakikolwiek element spełnia warunek
         * @param predicate Warunek
         */
        any<T>(predicate: (item: T) => boolean): boolean;
    }
    interface Date {
        /** Pobiera number tygodnia w roku */
        getWeek(): number;
    }
}

Array.prototype.all = function <T>(predicate: (item: T) => boolean) {

    if (!this || this.length == 0) {

        return false;
    }

    for (var element of this) {
        if (!predicate(element)) {
            return false;
        }
    }
    return true;
};

Array.prototype.any = function <T>(predicate: (item: T) => boolean) {

    if (!this) {

        return false;
    }

    for (var element of this) {
        if (predicate(element)) {
            return true;
        }
    }
    return false;
};

Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);

    var week1 = new Date(date.getFullYear(), 0, 4);

    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

export { };
